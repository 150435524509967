@font-face {
  font-family: "Noto Serif";
  src: url('../fonts/Noto_Serif/NotoSerif-Regular.ttf');
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway.ttf");
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
  min-height: 100dvh;
  max-height: 100dvh;
}

#root {
  overflow-x: hidden;
  overflow-y: hidden;
}