img {
    box-sizing: border-box;
    /*outline: 2px solid black;*/
}

.section-separator {
    margin-bottom: 0;
    margin-top: .5em;
}

.page-title {
    font-family: "Arial Black", sans-serif;
    color: saddlebrown;
    font-size: 2em;
    padding: 0 .5em .5em;
}