#form-container {
    min-width: 100%;
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#form-container a {
    font-size: 18px;
}

#form-container a {
    display: block;
}

.forms-photo {
    max-width: 100%;
    min-width: 100%;
}

#forms {
    min-width: 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    min-width: 50%;
    text-align: center;
}

#form-photos-container {
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#forms h1 {
    font-family: "Arial Black", "sans-serif";
    font-size: 24px;
    color: saddlebrown;
}

.page-title {
    padding: 0;
    font-family: Georgia, serif;
    font-size: 2em !important;
    margin: 1em 0;
}

#forms span {
    min-height: 25px;
    max-height: 25px;
    display: inline-flex;
    align-items: center;
    padding-top: 2px;
    margin-left: 5px;
}

#forms a {
    color: rgb(70, 179, 251) !important;
    display: inline-flex;
    min-width: 180px;
    max-width: 180px;
    border: 2px outset black;
    box-shadow: 2px 2px 5px black;
    text-decoration: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: sans-serif;
    transition: background-color 500ms;
}

#forms a:hover {
    background-color: gray !important;
    opacity: .5;
    transition: background-color 500ms;
}

#forms img {
    display: inline-block;
    border: none !important;
    outline: none !important;
    max-width: 25px;
    min-width: 25px;
    margin-right: 5px;
}

#forms-picture-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
}

.forms-photo-container {
    min-width: 30%;
    max-width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 750px) {
    #form-container {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    #forms {
        min-width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .forms-photo {
        max-width: 80%;
        min-width: 80%;
    }

    .forms-photo-container {
        min-width: 50%;
        max-width: 50%;
    }
}

.form-link-spacer {
    min-width: 100%;
    max-width: 100%;
    min-height: 5px;
    max-height: 5px;
}