#board-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    padding-top: .5em;
}

#board-members {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
    max-width: 100%;
}

.board-member {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
    max-width: 100%;
    /* background-color: rgb(205, 205, 205); */
    /* box-shadow: 0 .5em 0 0 rgb(47, 65, 37); */
    border-bottom: 1px solid black;
    overflow-x: visible;
    padding-bottom: 1em;
    margin-top: 1em;
    box-sizing: border-box;
}

.full-name {
    display: inline-flex;
    min-width: 30%;
    max-width: 30%;
    font-size: 1.5rem;
    flex-direction: row;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.position {
    display: inline-flex;
    min-width: 30%;
    max-width: 30%;
    flex-direction: row;
    justify-content: center;
    font-size: 1.3rem;
    text-decoration: underline;
}

.email {
    font-size: 1.1rem;
    display: inline-flex;
    min-width: 40%;
    max-width: 40%;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 750px) {
    .full-name {
        display: inline-flex;
        min-width: 50%;
        max-width: 50%;
        font-size: 1.5rem;
    }
    
    .position {
        display: inline-flex;
        min-width: 50%;
        max-width: 50%;
        text-decoration: underline;
    }
    
    .email {
        display: inline-flex;
        min-width: 100%;
        max-width: 100%;
        padding: .5em;
    }
    
}