#global-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    min-width: 100%;
    max-width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    position: relative;
    /*background-color: blue;*/
}

#overflow-wrapper {
    min-width: 100%;
    max-width: 100%;
    min-height: 100dvh;
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

#shrink-wrapper {
    /*background-color: yellow;*/
    min-height: fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: static;
    background-color: aliceblue;
    box-shadow: 0 0 10px black;
    /* border-left: 1px solid black;
    border-right: 1px solid black; */

}
