#footer {
    box-sizing: border-box;
    background-color: #2a2a2a;
    color: aliceblue;
    min-width: 100%;
    max-width: 100%;
    min-height: fit-content;
    max-height: fit-content;
    padding: 1em 1em;
    font-family: "Noto Serif", serif;
}

a:visited {
    color: rgb(70, 179, 251) !important;
    text-decoration: underline;
}

.highlight-link {
    color: rgb(70, 179, 251) !important;
    text-decoration: underline;
}

#highlight-title {
    color: rgb(70, 179, 251) !important;
    text-decoration: underline;
}

#footer-nav-link-container {
    padding-bottom: .5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
    max-width: 100%;
    min-height: fit-content;
    max-height: fit-content;
}

.page-title {
    margin-top: 6px !important;
}

.footer-nav-link-containers {
    min-width: 150px;
    max-width: 150px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: .5em;
    padding-left: .5em;
    padding-right: .5em;
}

.footer-nav-link-titles {
    display: block;
    text-align: center;
    font-weight: bold;
    /* text-decoration: underline; */
}

.footer-nav-sub-link-containers {
    text-align: center;
}

.footer-nav-sub-links {
    display: block;
}

.copyright {
    display: block;
    text-align: center;
}