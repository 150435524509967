#brand-header {
    min-width: 100%;
    max-width: 100%;
    min-height: 3em;
    max-height: 3em;
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 15px 10px -15px #111;  
}

.show {
    color: rgb(70, 179, 251) !important;
    text-decoration: underline;
}

#nav-bar {
    position: fixed;
        min-width: 100%;
        max-width: 100%;
        min-height: 60px;
        max-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(50, 64, 76);
    box-shadow: 0 0 10px black;
    padding: 0 5px;
    box-sizing: border-box;
    text-align: center;
    transition: all 1s;
    /*overflow-x: scroll !important;*/
}

#nav-button {
    position: fixed;
    /*top: Calc(100vh - 75px);*/
    /*left: Calc(100vw - 75px);*/
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    /*background-color: red;*/
    border-radius: 50px;
    transition: all 1s;
}

#nav-button img {
    min-width: 100%;
    max-width: 100%;
    transition: all 1s;
}

#nav-button img:hover {
    transform: scale(130%);
    transition: all 1s;
}

#nav-button img:active {
    transform: rotate(90deg);
}

.nav-object {
        min-width: Calc(100vw/8);
        max-width: Calc(100vw/8);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Montserrat, sans-serif;
        font-weight: 600;
}

.nav-links-container {
    display: none;
}

#fwcas-logo {
    min-height: 60px;
    max-height: 60px;
    outline: none;
    display: block;
}

#fwcas-logo-container {
    box-sizing: border-box;
    min-width: fit-content;
    max-width: fit-content;
    min-height: fit-content;
    max-height: fit-content;
}

@media screen and (max-width: 750px) {
    #nav-bar {
        position: fixed;
        background-color: rgb(50, 64, 76);
        min-width: 7em;
        max-width: 7em;
        /* height: 100vh; fallback for Js load
        height: var(--doc-height); */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 0;
        transition: all 1s;
        /*padding: 0 5px;*/
        /*box-sizing: border-box;*/
        /*text-align: center;*/
        /*overflow-x: scroll !important;*/
    }

    .nav-object {
        min-height: Calc(100vh/8);
        max-height: Calc(100vh/8);

        min-width: unset !important;
        max-width: unset !important;

        /* font-size: .8em; */
        /*flex-grow: 1;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*font-family: Montserrat, sans-serif;*/
        /*font-weight: 600;*/
    }

    .nav-object span {
        font-size: .9em;
    }

    #fwcas-logo {
         min-width: 7em;
         max-width: 7em;
        min-height: unset !important;
        max-height: unset !important;
     }

    #fwcas-logo-container {
        box-sizing: border-box;
        /*min-width: 7em;*/
        /*max-width: 7em;*/
        /*min-height: fit-content;*/
        /*max-height: fit-content;*/
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-grow: unset !important;
    }
}