#events-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 20em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    overflow-y: auto !important;
}

.img-url-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

#event-model-main-container {
    min-width: 75%;
    max-width: 75%;
    padding: .5em;
    min-height: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
}

.img-url-container span {
    padding: 0 .5rem;
}
@media screen and (max-width: 750px) {
    #event-model-main-container {
        min-width: 95%;
        max-width: 95%;
    }
}

#events-container img {
    position: absolute;
    bottom: 5em;
    left: Calc(50% - 2.5em);
    min-width: 5em;
    max-width: 5em;
    animation: move 1s infinite;
}

.an-event-container {
    min-width: 50%;
    max-width: 50%;
    min-height: fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    user-select: none;
    box-shadow: .2em .2em .2em black;
    margin: 1em 0;
    padding: .5em;
    box-sizing: border-box;
}

#animation-container {
    min-height: 20em;
    max-height: 20em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.lds-heart {
    justify-self: center !important;
    min-height: fit-content;
    max-height: fit-content;
}

@media screen and (max-width: 750px) {
    .an-event-container {
        min-width: 95%;
        max-width: 95%;
    }
}

.cms-buttons-container > button {
    margin: 0 .5em;
}

.cms-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.an-event-container span {
    display: inline-block;
    font-size: 1.5em;
    font-family: Montserrat, sans-serif;
    text-align: center;
    box-sizing: border-box;
    padding-bottom: .3em;
    color: rgb(58, 74, 87) !important;
}

.an-event-container a span {
    text-decoration: underline;
}

.page-title {
    justify-self: flex-start;
}

.an-event-container a span {
    min-width: 100%;
}

.event-meta-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
}

.event-meta-info-container span {
    font-size: 1em;
}

@keyframes move {
    0% {
        transform: scale(100%);
    }
    50% {
        transform: scale(110%);
    }
    100% {
        transform: scale(100%)
    }
}

#new-page-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 1em;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    background-color: papayawhip;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

#attributes-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
}

#new-page-container form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
    max-width: 100%;
}

#attributes-container input {
    min-width: 15em;
    max-width: 15em;
    font-size: 1em;
    padding: .5em;
    margin: 0.5em 0.5em;
    border-radius: .5em;
    font-family: Montserrat, sans-serif;
}

#attributes-container input[type="file"] {
    text-align: center;
    min-width:fit-content;
    max-width: fit-content;
}

#editor-container textarea {
    background-color: red;
    min-height: 1000px;
}

#event-submit-button {
    font-family: Montserrat, sans-serif;
    padding: 0.5em;
    margin-top: 2em;
    background-color: dodgerblue;
    border-radius: .5em;
    outline: none;
    border: 1px outset black;
    color: white;
}

#event-submit-button:active {
    border: 1px inset black;
    padding-top: .6em;
    padding-bottom: .4em;
}

#loading-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#event-meta-info-container {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 1em;
}

#event-meta-info-container span {
    font-size: 1em !important;
}

#event-image-container {
    min-width: 100%;
    max-width: 100%;
    min-height: fit-content;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    position: relative;
}

#event-image-sub-container {
    min-width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}



.img-button {
    min-width: 2em;
    max-width: 2em;
    min-height: 2em;
    max-height: 2em;
    padding: 1em;
    position: absolute;
    top: Calc(50% - 2.5em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 5em;
}

.img-button img {
    background-color: white;
    border-radius: 5em;
}

#img-left-button {
    left: 2em;
}

#img-right-button {
    right: 2em;
    transform: rotateZ(180deg);
}

#event-image-sub-container img {
    min-height: auto;
    max-height: 30em;
    min-width: auto;
    max-width: 100%;
}

#open-event-page {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#open-event-page span {
    font-size: 1.5em;
    font-family: Montserrat, sans-serif;
    text-align: center;
}

#event-title {
    font-size: 2em !important;
    padding: .5em;
    color: saddlebrown;
    font-family: "Arial Black", "sans-serif" !important;
    text-align: center;
}

#event-model-container {
    text-align: justify !important;
    font-size: 1.3em !important;
    text-justify: newspaper;
}

@media screen and (max-width: 750px) {

}