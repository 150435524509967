#login-main-container {
    min-height: 500px;
    max-height: 500px;
    min-width: 100%;
    max-width: 100%;
    /*background-color: red;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#authorized-container {
    min-height: 500px;
    max-height: 500px;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#authorized-container span {
    margin-top: 1em;
    font-size: 1.5em;
    color: saddlebrown;
    font-family: "Arial Black", "sans-serif";
}

#login-screen form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#login-screen form input {
    font-size: 1em;
    padding: .5em;
    border-radius: 1em;
    border: unset;
    margin: .5em 0;
    text-align: center;
    font-family: Montserrat, sans-serif;
}
#login-screen form button {
    font-family: Montserrat, sans-serif;
    margin-top: .5em;
    padding: .3em;
}

#pin-pad-container {
    min-height: 400px;
    max-height: 400px;
    min-width: 300px;
    max-width: 300px;
    /*background-color: blue;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.pin-pad-button {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px outset black;
    font-family: "Courier New", sans-serif;
    font-size: 24px;
    font-weight: bold;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.pin-pad-button:active {
    border: 1px inset black;
    background-color: black;
    color: white;
}