#home-container {
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
}

#banner-img {
    max-width: 100%;
    min-width: 100%;
}

#banner-container {
    max-width: fit-content;
    min-width: fit-content;
}

#home-container h1 {
    font-family: Georgia, serif !important;
    font-size: 24px;
    text-align: center;
}

#home-container p {
    max-width: 80%;
    font-family: Raleway, sans-serif;
    float: right;
    text-align: justify;
    margin-top: 0;
}

#mission-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mission-title {
    font-size:  32px;
    text-align: center;
    /*font-weight: bold;*/
    font-family: "Arial Black", "sans-serif";
    color: saddlebrown;
}

#mission-main-container h1 {
    padding: 0;
    font-family: Georgia, serif;
    font-size: 24px !important;
    margin-bottom: 0;
}

#mission-title-picture-container {
    /*margin-top: 10px;*/
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
}

#mission-title-picture-container img {
    max-width: 30%;
    min-width: 30%;
}

.statement-first-word {
    display: inline-block;
    color: saddlebrown;
    font-size: 24px;
    font-family: "Arial Black", "sans-serif";
}

.statement-regular {
    display: inline-block;
    font-size: 24px;
    font-family: "sans-serif";
    min-width: 65%;
    max-width: 65%;
    text-align: justify;
    text-justify: newspaper;
}

.statement-photo-container {
    min-width: 20%;
    max-width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#mission-statements-container {
    max-width: 75%;
    min-width: 75%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mission-statements-container span:nth-child(1) {
    margin-bottom: .5em;
}

.statement-picture {
    min-width: 100%;
    max-width: 100%;
    justify-self: center;
    align-self: center;
}

@media screen and (max-width: 750px) {


    #mission-main-container {

    }

    #mission-title {
        font-size: 24px;
    }

    #mission-title-picture-container img {
        max-width: 40%;
        min-width: 40%;
    }

    #mission-title-picture-container {
        justify-content: space-between;
    }

    .statement-photo-container {
        min-width: 25%;
        max-width: 25%;
    }

    .statement-picture {
        min-width: 100%;
        max-width: 100%;
        align-self: flex-start;
    }

    .statement-regular {
        font-size: 18px;
        box-sizing: border-box;
        min-width: 75%;
        max-width: 75%;
    }

    .statement-first-word {
        font-size: 18px;
    }

    #mission-statements-container {
        min-width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 1em;
    }

}

#links-main-container {
    margin-top: 1em;
    margin-bottom: 1em;
}

.link-containers h2 {
    padding: 0;
    margin: 0;
}

.padding-left {
    padding-left: 1em;
}

.padding-right {
    padding-right: 1em;
}

#links-main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#links-main-container h2 {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 1em;
    max-width: 200px;
}

.link-containers img {
    min-width: auto;
    max-height: 2.5em;
    min-height: 2.5em;
    cursor: pointer;
    margin: 1em;
}

.link-containers {
    justify-content: space-evenly;
}


#donate-button {
    max-height: 2.5em !important;
    min-height: 2.5em !important;
}

.link-containers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-top: 1em;
}

#link-structure-top {
    /*padding-bottom: 1em;*/
    box-sizing: border-box;
}

#link-structure-bottom {
    /*padding-bottom: 16px;*/
}

@media screen and (max-width: 750px) {
    #african-greys-perched {
        min-width: 90%;
        max-width: 90%;
        min-height: auto;
    }
}

#donate-button img {
    outline: none !important;
    min-width: auto;
    min-height: 50px;
    max-height: 50px;
}

.link-structure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%;
    max-width: 100%;
}

#outro-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.statement-even {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    box-sizing: border-box;
}

.statement-odd {
    min-width: 100%;
    max-width: 100%;
    padding: 0 1em;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 750px) {
    #outro-container {
        flex-direction: column;
        align-items: center;
        min-width: 100%;
        max-width: 100%;
    }
}

.outro-photos {
    min-width: 350px;
    max-width: 350px;
    min-height: auto;
}

.photo-container {
    padding: .5em;
}